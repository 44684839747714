<script setup>
import { ref } from 'vue';
import dateFormat from 'dateformat';
import HrbrDatepicker from '../ui/HrbrDatepicker.vue';
const props = defineProps({
  value: {
    type: String,
  },
});
const emit = defineEmits(['change', 'focus']);

const isCalendarOpen = ref(false);

const addSignDate = () => {
  emit('change', dateFormat(new Date(), 'mmm dd yyyy'));
};

const onValueChanged = (val) => {
  emit('change', dateFormat(val, 'mmm dd yyyy'));
};

const onKeyDown = () => {
  if (!props.value) {
    addSignDate();
  } else {
    isCalendarOpen.value = true;
  }
};
</script>

<template>
  <div
    class="annotation-date"
    tabindex="0"
    @keydown.enter="onKeyDown"
    @focus="emit('focus')"
  >
    <span
      v-if="!value"
      class="placeholder"
      @click="addSignDate"
    >
      Add date
    </span>
    <span
      v-else
      class="date-value"
      @click="isCalendarOpen = !isCalendarOpen"
    >
      {{ value }}
    </span>
    <HrbrDatepicker
      :value="value"
      :is-open="isCalendarOpen"
      :is-range="false"
      :has-shortcuts="false"
      :editable="false"
      icon-only
      @change="onValueChanged"
    />
  </div>
</template>

<style lang="postcss" scoped>
.annotation-date {
  display: flex;
  align-items: center;
  gap: 8px;
  outline: none;
  span {
    display: block;
    width: 100%;
  }
  >>> .datepicker-icon {
    display: none;
  }
}
.date-value, .placeholder {
  display: block;
  font-weight: 600;
}
.placeholder {
  color: #999;
  font-style: italic;
  font-size: inherit;
  cursor: pointer;
}

.date-value {
  color: #B015B3;
}
</style>
