<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { toPng } from 'html-to-image';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';

const props = defineProps({
  value: {
    type: String,
  },
  annotation: {
    type: Object,
  },
});
const emit = defineEmits(['change', 'keydown', 'focus']);

const magicAnnotationsStore = useMagicAnnotationsStore();
const {
  signaturePadModalOpened,
  signaturePadAnnotation,
  signatureNameUrl,
} = storeToRefs(magicAnnotationsStore);

const isLoading = ref(false);

const getDefaultSignature = () => {
  if (!props.value) {
    emit('change', signatureNameUrl.value);
  }
};
const openSignaturePadModal = () => {
  signaturePadModalOpened.value = true;
  signaturePadAnnotation.value = props.annotation;
};
const onKeyDown = () => {
  openSignaturePadModal();
};

onMounted(() => {
  getDefaultSignature();
});
</script>

<template>
  <div
    class="annotation-signature"
    v-bind="$attrs"
    tabindex="0"
    @keydown.enter="onKeyDown"
  >
    <div
      class="annotation-signature__value"
    >
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
        can-cancel
      >
        <b-icon
          pack="far"
          icon="spinner"
          size="is-medium"
          custom-class="fa-spin">
        </b-icon>
      </b-loading>
      <span
        v-if="!value"
      >
        Click to add signature
      </span>
      <img
        v-else
        :src="value"
        alt="signature"
        @click="openSignaturePadModal"
      >
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.annotation-signature {
  display: flex;
  align-self: center;
  width: 75% !important;
  height: 100%;
  outline: none;

  &__value {
    color: #999;
    font-weight: 600;
    font-style: italic;
    font-size: inherit;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  &__edit {
    align-self: center;
    margin-left: 10px;
    font-size: inherit;
    color: #B015B3;
    cursor: pointer;
  }

  :deep(.fa-spinner) {
    color: #B015B3;
  }
}
</style>
